import { Bloock, Network } from '@bloock/sdk';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  release: 'dashboard-web@' + process.env.REACT_APP_APP_VERSION
});

const apiHost = process.env.REACT_APP_API_HOST!;
if (apiHost.includes('api.bloock.dev')) {
  Bloock.setApiHost(process.env.REACT_APP_API_HOST!);

  Bloock.setProvider(Network.ETHEREUM_SEPOLIA, 'https://sepolia.bloock.dev');
  Bloock.setContractAddress(Network.ETHEREUM_SEPOLIA, '95d7DF00Dc4d5Ae047F3A40f864A92d337DBFdeB');
}

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS!
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
